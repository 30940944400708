import 'react-app-polyfill/ie11' // For IE 11 support
import 'react-app-polyfill/stable'
import './polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as request from 'request-promise-native'
import { updateConfig, config } from './config'
import * as serviceWorker from './serviceWorker'
import { icons } from './assets/icons'
import { Provider } from 'react-redux'
import store from './store'
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client'
import { offsetLimitPagination } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import './index.css'
import 'react-widgets/dist/css/react-widgets.css'

let configFile = 'config.json'
if (process.env.NODE_ENV === 'production') {
  configFile = 'config.production.json'
} else if (process.env.NODE_ENV === 'test') {
  configFile = 'config.test.json'
}

let clearCache = false

export const setClearCache = (v) => (clearCache = v)

request(`${window.location.origin}/${configFile}`, { json: true }, (err, res) => {
  if (!err) {
    updateConfig(res.body)

    const httpLink = createHttpLink({
      uri: `${config.baseUrl}${config.graphQLPath}`,
    })

    const authLink = setContext((_, { headers }) => {
      // get the authentication token from local storage if it exists
      const token = localStorage.getItem('loginToken')
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          authorization: token ? token : '',
        },
      }
    })

    const paginationMerge = (existing = [], incoming, { args: { skip = 0 }, variables: { startDate, endDate } }) => {
      // Slicing is necessary because the existing data is
      // immutable, and frozen in development.
      if (clearCache) {
        setClearCache(false)
        return incoming
      }
      if (startDate && endDate) {
        return incoming.concat(existing.filter(item => incoming.findIndex(i => i.__ref === item.__ref) < 0) || [])
      }
      const merged = existing ? existing.slice(0) : []
      for (let i = 0; i < incoming.length; ++i) {
        merged[skip + i] = incoming[i]
      }
      return merged
    }

    const paginationRead = (serviceCall = false) => (
      existing,
      {
        args: {
          // Default to returning the entire cached list,
          // if offset and limit are not provided.
          skip = 0,
          take = existing?.length,
        } = {},
        variables: {
          startDate,
          endDate
        } = {},
      },
    ) => {
      if (startDate && endDate && serviceCall) {
        return existing
      }
      return existing && existing.slice(skip, skip + take)
    }

    const cache = new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            users: {
              keyArgs: [],
              merge: paginationMerge,
              read: paginationRead(),
            },
            serviceCalls: {
              keyArgs: [],
              merge: paginationMerge,
              read: paginationRead(true),
            },
            equipmentS: {
              keyArgs: [],
              merge: paginationMerge,
              read: paginationRead(),
            },
            noSignatureReasons: {
              keyArgs: [],
              merge: paginationMerge,
              read: paginationRead(),
            },
          },
        },
      },
    })

    const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache,
      onError: ({ networkError, graphQLErrors }) => {
        console.log('graphQLErrors', graphQLErrors)
        console.log('networkError', networkError)
      },
    })

    React.icons = icons

    ReactDOM.render(
      <ApolloProvider client={client}>
        <Provider store={store}>
          <App />
        </Provider>
      </ApolloProvider>,
      document.getElementById('root'),
    )

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: http://bit.ly/CRA-PWA
    serviceWorker.unregister()
  }
})
