export default [
  {
    _tag: 'CSidebarNavItem',
    name: 'Service Calls',
    to: '/service-calls',
    icon: 'cil-mobile',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Reason Codes',
    to: '/reason-codes',
    icon: 'cil-notes',
    id: 'reason-codes',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Users',
    to: '/users',
    icon: 'cil-group',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Equipment',
    to: '/equipment',
    icon: 'cil-factory',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Locations',
    to: '/locations',
    icon: 'cil-building',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Timeline',
    to: '/timeline',
    icon: 'cil-av-timer',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Calendar',
    to: '/calendar',
    icon: 'cil-calendar',
  },
]
