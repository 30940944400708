import React, { useEffect, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { withRouter, useHistory } from 'react-router-dom'
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CRow,
} from '@coreui/react'
import '../styles.scss'
import { REGISTER } from '../../mutations'
import { CInputGroupText } from '@coreui/react/es'
import { useSelector, useDispatch } from 'react-redux'
import { ME } from "../../queries";
import { receiveUser } from "../../auth/actions";

const Register = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const userRole = useSelector(state => state?.auth?.user)?.roles?.find((role) => role?.name)
  const loginToken = localStorage.getItem('loginToken')

  const [registered, setRegistered] = useState(false)
  const [getMe, { data: userData }] = useLazyQuery(ME)

  const urlParams = new URLSearchParams(document.location.search.substring(1))
  const token = urlParams.get('token')

  const [formState, setFormState] = useState({
    name: '',
    password: '',
    confirmPassword: '',
  })

  const [register, { loading, error }] = useMutation(REGISTER, {
    variables: {
      token,
      name: formState.name,
      password: formState.password,
      confirmPassword: formState.confirmPassword,
    },
    onCompleted: async ({ register }) => {
      await localStorage.setItem('loginToken', register.token)
      setFormState({
        name: '',
        password: '',
        confirmPassword: '',
      })
      await getMe()
    },
    onError: (err) => {
      console.log(err)
    },
  })

  useEffect(() => {
    if (userData?.me) {
      dispatch(receiveUser(userData.me))
      setRegistered(true)
    }
  }, [userData])

  if (loginToken) {
    if (userRole && userRole?.name !== 'FIELDTECH') {
      history.push('/service-calls')
    }
    if (!userRole) {
      return null
    }
  }

  const renderBody = () => {
    if (registered || userRole?.name === 'FIELDTECH') {
      return (
        <div>
          <h3>Thank you for registering your account!</h3>
          <p className="text-muted">You can now use these credentials to sign into the Field Service mobile app.</p>
        </div>
      )
    } else {
      return (
        <CForm>
          <h1>Register</h1>
          <p className="text-muted">Create your account</p>
          <CInputGroup className="mb-3">
            <CInputGroupPrepend>
              <CInputGroupText style={{ width: 35 }}>
                <i className={'fa fa-pencil'} />
              </CInputGroupText>
            </CInputGroupPrepend>
            <CInput
              required
              type="text"
              placeholder="Name"
              autoComplete="name"
              value={formState.name}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  name: e.target.value,
                })
              }
            />
          </CInputGroup>
          <CInputGroup className="mb-3">
            <CInputGroupPrepend>
              <CInputGroupText style={{ width: 35 }}>
                <i className={'fa fa-lock'} />
              </CInputGroupText>
            </CInputGroupPrepend>
            <CInput
              required
              type="password"
              placeholder="Password"
              autoComplete="password"
              value={formState.password}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  password: e.target.value,
                })
              }
            />
          </CInputGroup>
          <CInputGroup className="mb-4">
            <CInputGroupPrepend>
              <CInputGroupText style={{ width: 35 }}>
                <i className={'fa fa-lock'} />
              </CInputGroupText>
            </CInputGroupPrepend>
            <CInput
              required
              type="password"
              placeholder="Confirm password"
              autoComplete="confirm-password"
              value={formState.confirmPassword}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  confirmPassword: e.target.value,
                })
              }
            />
          </CInputGroup>
          <CAlert color={'warning'}>
            <ul style={{ paddingLeft: '1em', marginBottom: 0 }}>
              <li>MUST contain at least 8 characters (12+ recommended)</li>
              <li>MUST contain at least one uppercase letter.</li>
              <li>MUST contain at least one lowercase letter.</li>
              <li>MUST contain at least one number.</li>
              <li>MUST contain at least one special character.</li>
            </ul>
          </CAlert>
          {error && (
            <CAlert color={'error'}>
              {error.message ? error.message : 'An error occurred creating your account'}
            </CAlert>
          )}
          <CButton color="primary" block disabled={loading} onClick={register}>
            Create Account
          </CButton>
        </CForm>
      )
    }
  }

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="9" lg="7" xl="6">
            <CCard className="mx-4">
              <CCardBody className="p-4">
                {renderBody()}
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default withRouter(Register)
