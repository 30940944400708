import React from 'react'
import { useSelector } from 'react-redux'
import { connect } from 'react-redux'
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { useHistory } from 'react-router'
import { signOut } from '../../auth/actions'
import { setDarkMode, setSidebar } from '../actions'
import logo from '../../assets/images/BSI_WideLogo_Light.png'
import logoDark from '../../assets/images/BSI_WideLogo_Dark.png'
import { UPDATE_USER_UIMODE } from "../../mutations";
import { useMutation } from "@apollo/client";

const TheHeader = ({ signOut, setSidebar, setDarkMode }) => {
  const { sidebarShow, darkMode } = useSelector((state) => state.layout)
  const userId = useSelector((state) => state.auth.user.id)
  const [updateUIMode] = useMutation(UPDATE_USER_UIMODE)

  const history = useHistory()

  const toggleDarkMode = async() => {
    setDarkMode(!darkMode)
    await updateUIMode({
      variables: {
        id: userId,
        uiMode: darkMode ? 'LIGHT' : 'DARK',
      },
    })
  }

  const toggleSidebar = () => {
    const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive'
    setSidebar(val)
  }

  const toggleSidebarMobile = () => {
    const val = [false, 'responsive'].includes(sidebarShow) ? true : 'responsive'
    setSidebar(val)
  }

  return (
    <CHeader style={{ justifyContent: 'space-between' }}>
      <CToggler inHeader className="ml-md-3 d-lg-none" onClick={toggleSidebarMobile} />
      <CToggler inHeader className="ml-3 d-md-down-none" onClick={toggleSidebar} />
      <CHeaderBrand className="mx-auto d-lg-none" to="/">
        <img alt={'Boiler Specialists'} src={darkMode ? logoDark : logo} height={28} />
      </CHeaderBrand>
      <CHeaderNav className="px-3">
        <CToggler
          inHeader
          className="ml-3 d-md-down-none"
          onClick={toggleDarkMode}
          title="Toggle Light/Dark Mode">
          <CIcon name="cil-moon" className="c-d-dark-none" alt="CoreUI Icons Moon" />
          <CIcon name="cil-sun" className="c-d-default-none" alt="CoreUI Icons Sun" />
        </CToggler>
        <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
          <CDropdownToggle className="c-header-nav-link" caret={false}>
            <div className="c-avatar">
              <i className={'fa far fa-lg fa-user-circle'} />
            </div>
          </CDropdownToggle>
          <CDropdownMenu className="pt-0" placement="bottom-end">
            <CDropdownItem
              onClick={() => {
                signOut().then(() => {
                  localStorage.removeItem('loginToken')
                  history.push('/')
                })
              }}>
              <CIcon name="cil-lock-locked" className="mfe-2" />
              Sign Out
            </CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
      </CHeaderNav>
    </CHeader>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: async () => dispatch(signOut()),
    setDarkMode: (val) => dispatch(setDarkMode(val)),
    setSidebar: (show) => dispatch(setSidebar(show)),
  }
}

export default connect(null, mapDispatchToProps)(TheHeader)
