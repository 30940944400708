import { SIGN_OUT, RECEIVE_USER } from './actions'

const loginToken = localStorage.getItem('loginToken')

const initState = {
  loginToken,
  user: null,
}

const auth = (state = initState, action) => {
  const { user } = action
  switch (action.type) {
  case SIGN_OUT:
    return {
      ...state,
      loginToken: null,
      isFetching: false,
    }
  case RECEIVE_USER:
    return {
      ...state,
      user,
    }
  default:
    return state
  }
}

export default auth
