export const onKeyPress = (key) => {
  switch(key.code) {
    case 'Enter':
      onPressEnter()
    default:
      return
  }
}

const onPressEnter = () => {
  if(document.activeElement.type === 'textarea') {
    return
  }
  const modals = document.querySelectorAll('.modal.show')
  if(modals?.length > 0) {
    const topModal = modals[modals.length - 1]
    const footers = topModal.querySelectorAll('.modal-footer')
    if(footers?.length > 0) {
      const topFooter = footers[footers.length - 1]
      let footerButtons = topFooter.querySelectorAll('#btn-click')
      let targetButton = null
      if(footerButtons?.length > 0) {
        targetButton = footerButtons[footerButtons.length - 1]
        if(targetButton.disabled) {
          targetButton = null
        }
      }
      if (!targetButton) {
        footerButtons = topFooter.querySelectorAll('#btn-cancel-click')
        if(footerButtons?.length > 0) {
          targetButton = footerButtons[footerButtons.length - 1]
        }
      }
      if(targetButton?.click) {
        targetButton.click()
      }
    }
  }
}
