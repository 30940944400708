import React from 'react'
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from '@coreui/react'
import { setSidebar } from '../actions'
import { connect } from 'react-redux'
import logo from '../../assets/images/BSI_WideLogo_Dark.png'
import icon from '../../assets/images/BSI_Icon.png'

// sidebar nav config
import navigation from '../_nav'

const TheSidebar = ({ setSidebar, show }) => {
  return (
    <CSidebar show={show} onShowChange={(val) => setSidebar(val)}>
      <CSidebarBrand className="d-md-down-none" to="/">
        <img className={'c-sidebar-brand-full'} alt={'Boiler Specialists'} height={28} src={logo} />
        <img className={'c-sidebar-brand-minimized'} alt={'Boiler Specialists Icon'} height={28} src={icon} />
      </CSidebarBrand>
      <CSidebarNav>
        <CCreateElement
          items={navigation}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  )
}

const mapDispatchToProps = {
  setSidebar,
}

const mapStateToProps = (state) => {
  return {
    show: state.layout.sidebarShow,
  }
}

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(TheSidebar))
