import { SET_ACTIVE_TABLE_FILTERS, ADD_SERVICE_CALL_MODAL, REMOVE_SERVICE_CALL_MODAL, OPEN_SERVICE_CALL_MODAL, CLOSE_SERVICE_CALL_MODAL } from './actions'

const initState = {
  activeTableFilters: {},
  serviceCallModals: []
}

const serviceCalls = (state = initState, action) => {
  switch(action.type) {
    case SET_ACTIVE_TABLE_FILTERS:
      return {
        ...state,
        activeTableFilters: action.payload,
      }
    case ADD_SERVICE_CALL_MODAL:
      const modalData = {
        open: true,
        ...action.payload,
      }
      return {
        ...state,
        serviceCallModals: [...state.serviceCallModals, modalData]
      }
    case REMOVE_SERVICE_CALL_MODAL:
      return {
        ...state,
        serviceCallModals: [...state.serviceCallModals.filter((modal, index) => index !== action?.payload?.modalIndex)]
      }
    case OPEN_SERVICE_CALL_MODAL:
      return {
        ...state,
        serviceCallModals: state.serviceCallModals.map((modal, index) => index ===  action?.payload?.modalIndex ? {...modal, open: true} : modal)
      }
    case CLOSE_SERVICE_CALL_MODAL:
      return {
        ...state,
        serviceCallModals: state.serviceCallModals.map((modal, index) => index ===  action?.payload?.modalIndex ? {...modal, open: false} : modal)
      }
    default:
      return state
  }
}

export default serviceCalls
