import { combineReducers } from 'redux'
import layout from './layout/reducer'
import auth from './auth/reducer'
import serviceCalls from './serviceCalls/reducer'
import equipment from './equipment/reducers'
import noSignatureReasons from './reasonCodes/noSignatureReasonReducer'
import emergencyCalls from './reasonCodes/emergencyCallReducer'
import users from './users/reducer'
import locations from './locations/reducer'

const appReducer = combineReducers({
  layout,
  auth,
  serviceCalls,
  equipment,
  noSignatureReasons,
  emergencyCalls,
  users,
  locations,
})

const rootReducer = (state, action) => {
  if (action.type === 'SIGN_OUT') state = undefined
  return appReducer(state, action)
}

export default rootReducer
