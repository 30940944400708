import { SET_ACTIVE_TABLE_FILTERS } from './actions'

const initState = {
  activeTableFilters: {},
}

const equipment = (state = initState, action) => {
  switch(action.type) {
    case SET_ACTIVE_TABLE_FILTERS:
      return {
        ...state,
        activeTableFilters: action.payload,
      }
    default:
      return state
  }
}

export default equipment
