export const equipment = `{
  id
  uuid
  customerBoilerId
  boilerMake
  boilerModel
  boilerSerial
  boilerNationalBoard
  location
  boilerStateId
  boilerMAWP
  burnerMake
  burnerModel
  historical
  deleted
  serviceReports {
    id
  }
  combustionReports {
    id
  }
  ovenFurnaceSafetyReports {
    id
  }
}`

export const combustionReading = `{
  id
  uuid
  o2
  co2
  co
  nox
  stackTemp
  efficiency
  steam
  hotWater
  furnacePressure
  manifoldPressure
  pumpInletOil
  oilNozzle
  bypass
  atomizingAir
  smokeNumber
  fuelType
}`

export const combustionReport = `{
  id
  uuid
  serviceCall{
      id
  }
  equipment ${equipment}
  readings(orderBy: {
    id: asc
  }) ${combustionReading}
  updatedAt
  pilot
  main
  ambientTemp
  plwcoPass
  plwcoNA
  plwcoComments
  alwcoPass
  alwcoNA
  alwcoComments
  hlpcPass
  hlpcNA
  hlpcSP
  hlpcSPF
  hlpcSPUnit
  hlpcComments
  mpcPass
  mpcNA
  mpcSP
  mpcSPF
  mpcSPUnit
  mpcComments
  opcPass
  opcNA
  opcSP
  opcSPF
  opcSPUnit
  opcComments
  hgpsPass
  hgpsNA
  hgpsSP
  hgpsSPF
  hgpsSPUnit
  hgpsComments
  lgpsPass
  lgpsNA
  lgpsSP
  lgpsSPF
  lgpsSPUnit
  lgpsComments
  hopsPass
  hopsNA
  hopsSP
  hopsSPF
  hopsSPUnit
  hopsComments
  lopsPass
  lopsNA
  lopsSP
  lopsSPF
  lopsSPUnit
  lopsComments
  capsPass
  capsNA
  capsSP
  capsSPF
  capsSPUnit
  capsComments
  ffrPass
  ffrNA
  ffrComments
  sputPass
  sputNA
  sputComments
  svltPass
  svltNA
  svltSP
  svltSPF
  svltSPUnit
  svltComments
  hwaPass
  hwaNA
  hwaComments
  esPass
  esNA
  esComments
  laasPass
  laasNA
  laasSP
  laasSPF
  laasSPUnit
  laasComments
  notes
  gasReadings
  oilReadings
  headerServiceCallId
  fuel2Pilot
  fuel2Main
  fuel2AmbientTemp
}`

export const ovenFurnaceSafetyReport = `{
  id
  uuid
  equipment ${equipment}
  serviceCall{
    id
  }
  gpacPass
  gpacComments
  elcPass
  elcComments
  mivPass
  mivComments
  ltropPass
  ltropComments
  sdpnbPass
  sdpnbComments
  essPass
  essComments
  ts2wusPass
  ts2wusComments
  plclPass
  plclComments
  cappPass
  cappSP
  cappSPF
  cappSPUnit
  cappComments
  bmsiPass
  bmsiSP
  bmsiSPF
  bmsiSPUnit
  bmsiComments
  circExAirPass
  circExAirSP
  circExAirSPF
  circExAirSPUnit
  circExAirComments
  esvPass
  esvComments
  misovPass
  misovComments
  st3p3Pass
  st3p3Comments
  gfsPass
  gfsComments
  sipPass
  sipSP
  sipSPF
  sipSPUnit
  sipComments
  gasRegPass
  gasRegSP
  gasRegSPF
  gasRegSPUnit
  gasRegComments
  prpVentPass
  prpVentComments
  oppPass
  oppSP
  oppSPF
  oppSPUnit
  oppComments
  fmgvltPass
  fmgvltComments
  vivcPass
  vivcComments
  mvctPass
  mvctComments
  smgvltPass
  smgvltComments
  vvvpsoPass
  vvvpsoComments
  vpccPass
  vpccComments
  mfvdvPass
  mfvdvComments
  pgroPass
  pgroComments
  prpVent2Pass
  prpVent2Comments
  fpgvltPass
  fpgvltComments
  spgvltPass
  spgvltComments
  lgfplsPass
  lgfplsSP
  lgfplsSPF
  lgfplsSPUnit
  lgfplsComments
  hgfplsPass
  hgfplsSP
  hgfplsSPF
  hgfplsSPUnit
  hgfplsComments
  smcPass
  smcComments
  ffrtPass
  ffrtComments
  sscPass
  sscComments
  etihtlPass
  etihtlSP
  etihtlSPF
  etihtlSPUnit
  etihtlComments
  ptfPass
  ptfComments
  pptPass
  pptSP
  pptSPF
  pptSPUnit
  pptComments
  titvPass
  titvSP
  titvSPF
  titvSPUnit
  titvComments
  lfpsiPass
  lfpsiComments
  lgfplspPass
  lgfplspSP
  lgfplspSPF
  lgfplspSPUnit
  lgfplspComments
  hgfplspPass
  hgfplspSP
  hgfplspSPF
  hgfplspSPUnit
  hgfplspComments
  sffPass
  sffComments
  fdstPass
  fdstComments
  fdssPass
  fdssSP
  fdssSPF
  fdssSPUnit
  fdssComments
  padpsPass
  padpsSP
  padpsSPF
  padpsSPUnit
  padpsComments
  tpfdPass
  tpfdSP
  tpfdSPF
  tpfdSPUnit
  tpfdComments
  dsPass
  dsComments
  sfPass
  sfComments
  ofaPass
  ofaComments
  farPass
  farComments
  rroPass
  rroComments
  ipiPass
  ipiComments
  fricPass
  fricComments
  uvsicPass
  uvsicComments
  notes
  headerServiceCallId
}`

export const part = `{
  id
  updatedAt
  number
  description
  inactive
}`

export const serviceReportPart = `
{
  id
  uuid
  part ${part}
  quantity
  number
  partType
  description
  workOrderPart {
    id
    part ${part}
    workOrder {
      id
    }
    description
    qtyRemaining
  }
}`

export const workOrder = `{
  id
  number
  orderDate
  client {
    id
    number
    fullName
    shortName
    equipment ${equipment}
    receiptContact {
      id
      number
      name
      email
      phone
    }
  }
  location {
    id
    line1
    line2
    number
    city
    state
    zip
    equipment ${equipment}
  }
  parts {
    id
    part {
      id
      description
      number
    }
    workOrder {
      id
    }
    description
    qtyRemaining
  }
  purchaseOrderNumber
  notes
  contactPhone
  contactName
  description
}`

export const serviceReport = `{
  id
  uuid
  equipment ${equipment}
  serviceCall {
    id
    workOrder {
      id
      number
      orderDate
      client {
        id
        number
        fullName
        shortName
        receiptContact {
          id
          number
          name
          email
          phone
        }
      }
      location {
        id
        line1
        line2
        city
        state
        zip
      }
      purchaseOrderNumber
      notes
    }
    scheduledStart
    scheduledEnd
    status
  }
  updatedAt
  parts ${serviceReportPart}
  servicePerformed
  additionalWorkRequirements
  headerServiceCallId
  internalFollowUp
}`

export const photo = `
{
  id
  uuid
  notes
  uri
  equipment {
    customerBoilerId
  }
}`

export const serviceCall = `
{
  id
  workOrder ${workOrder}
  serviceReports ${serviceReport}
  combustionReports ${combustionReport}
  ovenFurnaceSafetyReports ${ovenFurnaceSafetyReport}
  serviceReportsFromChildren ${serviceReport}
  combustionReportsFromChildren ${combustionReport}
  ovenFurnaceSafetyReportsFromChildren ${ovenFurnaceSafetyReport}
  scheduledStart
  actualStart
  actualEnd
  scheduledEnd
  status
  submitTime
  signatureTime
  startTime
  employee {
    id
    firstName
    lastName
  }
  merged
  header
  signerName
  noSignatureCode
  noSignatureDescription
  customerSignature
  receiptEmail
  parentServiceCallId
  childServiceCalls {
    id
    workOrder ${workOrder}
    scheduledStart
    actualStart
    actualEnd
    scheduledEnd
    status
    submitTime
    startTime
    employee {
      id
      firstName
      lastName
      fullName
    }
    merged
    header
    signerName
    noSignatureCode
    noSignatureDescription
    customerSignature
    receiptEmail
    commuteTime
    richText
    photos ${photo}
    onHold
  }
  attachments {
    id
    name
    url
    attachToEmail
  }
  attachmentsFromChildren {
    id
    name
    url
    attachToEmail
  }
  commuteTime
  richText
  photos ${photo}
  onHold
  newCustomerName
  newLocation
  newSupervisorName
  callerName
  callBackNumber
  callTime
  paymentType
  paymentTypeDescription
  billingAddress
  issueDescription
  timeOnly
}`

export const client = `
{
  id
  number
  fullName
  shortName
  receiptContact {
    id
    number
    name
    email
    phone
  }
  workOrders {
    serviceCalls {
      id
      photos ${photo}
      actualEnd
    }
  }
}`

export const location = `
{
  id
  number
  name
  line1
  line2
  city
  state
  zip
  formatted
  clientNumber
  client ${client}
}`
