export const SET_ACTIVE_TABLE_FILTERS = 'SET_ACTIVE_TABLE_FILTERS'
export const ADD_SERVICE_CALL_MODAL = 'ADD_SERVICE_CALL_MODAL'
export const REMOVE_SERVICE_CALL_MODAL = 'REMOVE_SERVICE_CALL_MODAL'
export const UPDATE_SERVICE_CALL_MODAL = 'UPDATE_SERVICE_CALL_MODAL'
export const OPEN_SERVICE_CALL_MODAL = 'OPEN_SERVICE_CALL_MODAL'
export const CLOSE_SERVICE_CALL_MODAL = 'CLOSE_SERVICE_CALL_MODAL'

export const setActiveTableFilters = (filters) => {
  return {
    type: SET_ACTIVE_TABLE_FILTERS,
    payload: filters,
  }
}

export const addServiceCallModal = args => {
  return {
    type: ADD_SERVICE_CALL_MODAL,
    payload: {
      ...args
    }
  }
}

export const removeServiceCallModal = modalIndex => {
  return {
    type: REMOVE_SERVICE_CALL_MODAL,
    payload: {
      modalIndex
    }
  }
}

export const updateServiceCallModal = (serviceCall, modalIndex) => {
  return {
    type: UPDATE_SERVICE_CALL_MODAL,
    payload: {
      serviceCall,
      modalIndex
    }
  }
}

export const openServiceCallModal = modalIndex => {
  return {
    type: OPEN_SERVICE_CALL_MODAL,
    payload: {
      modalIndex
    }
  }
}

export const closeServiceCallModal = modalIndex => {
  return {
    type: OPEN_SERVICE_CALL_MODAL,
    payload: {
      modalIndex
    }
  }
}
