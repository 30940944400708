import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { TheContent, TheSidebar, TheAside, TheFooter, TheHeader } from './components'
import { useHistory } from 'react-router-dom'
import './DefaultLayout.css'

const DefaultLayout = () => {
  const history = useHistory()

  const loginToken = localStorage.getItem('loginToken')
  const userRole = useSelector(state => state?.auth?.user)?.roles?.find((role) => role?.name)
  if (!loginToken) history.push('/')

  const darkMode = useSelector((state) => state.layout.darkMode)
  const classes = classNames('c-app c-default-layout', darkMode && 'c-dark-theme')

  if (userRole?.name === 'FIELDTECH') {
    history.push('/register')
  }
  if (!userRole) {
    return null
  }

  return (
    <div className={classes}>
      <TheSidebar />
      <TheAside />
      <div className="c-wrapper">
        <TheHeader />
        <div className="c-body">
          <TheContent />
        </div>
        <TheFooter />
      </div>
    </div>
  )
}

export default DefaultLayout
