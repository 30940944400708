import React from 'react'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import './scss/style.scss'
import DefaultLayout from './layout/DefaultLayout'
import SignIn from './signIn/screens/SignIn'
import Register from './register/screens/Register'
import './App.scss'
import { useDispatch } from 'react-redux'
import { receiveUser, signOut } from './auth/actions'
import { useQuery } from '@apollo/client'
import { ME } from './queries'
import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment'
import useEventListener from "./hooks/useEventListener"
import { onKeyPress } from "./utils/keyPressHandlers"

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

Moment.locale('en')
momentLocalizer()

const App = () => {
  const dispatch = useDispatch()
  useQuery(ME, {
    fetchPolicy: 'network-only',
    onCompleted: ({ me }) => {
      dispatch(receiveUser(me))
    },
    onError: error => {
      console.log(error)
      dispatch(signOut())
    }
  })

  useEventListener('keypress', onKeyPress)

  return (
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route exact path={'/'} name={'Sign In'} render={(props) => <SignIn {...props} />} />
          <Route exact path={'/register'} name={'Register'} render={(props) => <Register {...props} />} />
          <Route path="/users" name="Users" render={(props) => <DefaultLayout {...props} />} />
          <Route path="/service-calls" name="Service Calls" render={(props) => <DefaultLayout {...props} />} />
          <Route
            path={'/reason-codes/:tab?'}
            name={'Reason Codes'}
            render={(props) => <DefaultLayout {...props} />}
          />
          <Route
            path={'/equipment'}
            name={'Equipment'}
            render={(props) => <DefaultLayout {...props} />}
          />
          <Route
            path={'/locations/:tab?'}
            name={'Locations'}
            render={(props) => <DefaultLayout {...props} />}
          />
          <Route
            path={'/timeline'}
            name={'Timeline'}
            render={(props) => <DefaultLayout {...props} />}
          />
          <Route
            path={'/calendar'}
            name={'Calendar'}
            render={(props) => <DefaultLayout {...props} />}
            />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  )
}

export default App
