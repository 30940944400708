import { gql } from '@apollo/client/core'
import {serviceCall, workOrder, location, photo, equipment} from './selections'

export const ME = gql`
  query Me {
    me {
      id
      email
      roles {
        id
        name
      }
      name
      uiMode
    }
  }
`

export const GET_REPORT_FROM_S3 = gql`
  query GetReportFromS3($id: Int!, $type: String!) {
    getReportFromS3(id: $id, type: $type)
  }
`

export const GET_ALL_REPORTS_FROM_S3 = gql`
  query GetAllReportsFromS3($id: Int!) {
    getAllReportsFromS3(id: $id)
  }
`

export const NO_SIGNATURE_REASONS = gql`
  query NoSignatureReasons($skip: Int, $take: Int, $where: NoSignatureReasonWhereInput, $orderBy: [NoSignatureReasonOrderByInput!]) {
    noSignatureReasons(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
      id
      description
      code
    }
    noSignatureReasonCount(where: $where)
  }
`

export const USERS = gql`
  query Users($skip: Int, $take: Int, $where: UserWhereInput, $orderBy: [UserOrderByInput!]) {
    users(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
      id
      email
      roles {
        id
        name
      }
      name
      password
      employee {
        id
        fullName
      }
    }
    userCount(where: $where)
  }
`

export const USER = gql`
  query User($id: Int!) {
    user(where: { id: $id }) {
      id
      email
      roles {
        id
        name
      }
      name
      password
      employee {
        id
        number
        fullName
      }
    }
  }
`

export const USER_ROLES = gql`
  query Roles {
    __type(name: "Role") {
      name
      enumValues {
        name
      }
    }
  }
`

export const SERVICE_CALLS = gql`
  query ServiceCalls($skip: Int, $take: Int, $where: ServiceCallWhereInput, $orderBy: [ServiceCallOrderByInput!]) {
    serviceCalls(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
      id
      workOrder {
        id
        number
        purchaseOrderNumber
        client {
          id
          fullName
          shortName
        }
        location {
          id
          line1
          line2
          number
          city
          state
          zip
        }
        description
      }
      scheduledStart
      scheduledEnd
      actualStart
      actualEnd
      childServiceCalls {
        id
        status
      }
      employee {
        id
        firstName
        lastName
        fullName
      }
      status
      signerName
      receiptEmail
      header
      merged
      emailSent
      hasInternalFollowUp
      pdfUrl
      onHold
      timeOnly
    }
    serviceCallCount(where: $where)
  }
`

export const CHILD_SERVICE_CALLS = gql`
  query ChildServiceCalls($parentId: Int!) {
    serviceCall(where: { id: $parentId }) {
    childServiceCalls {
        id
        workOrder {
          id
          number
          purchaseOrderNumber
          client {
            id
            fullName
            shortName
          }
          location {
            id
            line1
            line2
            number
            city
            state
            zip
          }
          description
        }
        scheduledStart
        actualStart
        actualEnd
        scheduledEnd
        status
        startTime
        employee {
          id
          firstName
          lastName
          fullName
        }
        signerName
        receiptEmail
        emailSent
        hasInternalFollowUp
        onHold
        pdfUrl
        timeOnly
      }
    }
  }
`

export const SERVICE_CALLS_TIMELINE = gql`
  query ServiceCallsTimeline($startDate: DateTime!, $endDate: DateTime!) {
    serviceCalls(where: {
      OR: [
        {
          AND: [
            {
              scheduledStart: {
                gte: $startDate
              },
            },
            {
              scheduledStart: {
                lte: $endDate
              }
            }
          ]
        },
        {
          AND: [
            {
              scheduledEnd: {
                gte: $startDate
              },
            },
            {
              scheduledEnd: {
                lte: $endDate
              }
            }
          ]
        }
      ],
      header: {
        equals: false
      },
      deleted: {
        equals: false
      },
      status: {
        not: {
          equals: CANCELLED
        }
      }
    }) {
      id
      workOrder {
        id
        number
        description
        client {
          id
          shortName
          fullName
        }
      }
      scheduledStart
      scheduledEnd
      employee {
        id
        firstName
        lastName
      }
      status
    }
  }
`

export const SERVICE_CALL = gql`
  query ServiceCall($id: Int!) {
    serviceCall(where: { id: $id })
    ${serviceCall}
  }
`

export const CHECK_SERVICE_CALL = gql`
  query ServiceCall($id: Int!) {
    serviceCall(where: { id: $id })
    {
      workOrder {
        location {
          id
        }
      }
    }
  }
`

export const EMPLOYEES = gql`
  query Employees {
    employees {
      id
      number
      fullName
      firstName
      lastName
      users {
        id
      }
    }
  }
`

export const PARTS = gql`
  query Parts {
    parts {
      id
      number
      description
    }
  }
`

export const SERVICE_REPORT_PARTS = gql`
  query ServiceReportParts($serviceReportId: Int!) {
    serviceReportParts (where: { serviceReportId: { equals: $serviceReportId }}) {
      id
      number
      quantity
      partType
      description
      part {
        id
        number
      }
    }
  }
`

export const COMBUSTION_REPORT_READINGS = gql`
  query CombustionReadings($combustionReportId: Int!) {
    combustionReadings (where: { combustionReportId: { equals: $combustionReportId }}) {
      id
      uuid
      o2
      co2
      co
      nox
      stackTemp
      efficiency
      steam
      hotWater
      furnacePressure
      manifoldPressure
      pumpInletOil
      oilNozzle
      bypass
      atomizingAir
      smokeNumber
    }
  }
`

export const WORK_ORDERS_BY_LOCATION = gql`
  query WorkOrdersByLocation($locationId: Int!) {
    workOrders (
      where: {
        locationId: { equals: $locationId }
      })
    {
      id
      number
      client {
        shortName
      }
    }
  }
`

export const CHECK_WORK_ORDER = gql`
query WorkOrder($number:String!){
  workOrder(where:{
    number:$number
  }) {
    id
    location {
      id
    }
  }
}
`

export const EQUIPMENT = gql`
  query Equipment($skip: Int, $take: Int, $where: Equipment_WhereInput, $orderBy:[Equipment_OrderByInput!]) {
    equipmentS(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
      id
      uuid
      location
      customerBoilerId
      boilerMake
      boilerModel
      boilerSerial
      boilerStateId
      boilerNationalBoard
      boilerMAWP
      burnerMake
      burnerModel
      burnerSerial
      client {
        id
        fullName
        shortName
        workOrders {
          serviceCalls {
            id
            actualEnd
            photos ${photo}
          }
        }
      }
      customerLocation {
        id
        number
        name
        line1
        line2
        city
        state
        zip
        clientNumber
        formatted
      }
      historical
      historicalUUID
      deleted
      updatedAt
      serviceReports {
        id
      }
      combustionReports {
        id
      }
      ovenFurnaceSafetyReports {
        id
      }
    }
    equipmentCount(where: $where)
  }
`

export const SERVICE_CALL_STATUSES = gql`
  query ServiceCallStatus {
    __type(name: "ServiceCallStatus") {
      name
      enumValues {
        name
      }
    }
  }
`

export const LOCATION = gql`
  query Location($id: Int!) {
    location(where: { id: $id })
    ${location}
  }
`

export const CHECK_LOCATION = gql`
  query Location($id: Int!) {
    location(where: { id: $id })
    {
      id
      number
    }
  }
`

export const LOCATIONS = gql`
  query Locations($skip: Int, $take: Int, $where: LocationWhereInput, $orderBy: [LocationOrderByInput!]) {
    locations(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
      id
      client {
        id
        shortName
      }
      name
      formatted
    }
    locationCount(where: $where)
  }
`

export const LOCATIONS_BY_CLIENT_ID = gql`
  query Locations($clientId: IntFilter!) {
    locations(where: {clientId: $clientId})
    {
      id
      name
    }
  }
`

export const MOBILE_LOCATIONS = gql`
  query MobileLocations($skip: Int, $take: Int, $where: MobileLocationWhereInput, $orderBy: [MobileLocationOrderByInput!]) {
    mobileLocations(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
      id
      locations{
        name
        id
      }
      client {
        id
        shortName
      }
      name
    }
    mobileLocationCount(where: $where)
  }
`

export const GET_CLIENTS = gql`
  query GetClients {
    clients {
      id
      shortName
    }
  }
`

export const MOBILE_LOCATION = gql`
query MobileLocation($id: Int!) {
    mobileLocation(where: { id: $id })
  {
    name
    client {
      shortName
    }
    locations{
      name
    }
  }
}
`

export const PAYMENT_TYPES = gql`
  query PaymentTypes {
    __type(name: "PaymentType") {
      name
      enumValues {
        name
      }
    }
  }
`

export const SP_UNITS = gql`
  query SPUnits {
    __type(name: "SPUnit") {
      name
      enumValues {
        name
      }
    }
  }
`

export const HEADLESS_SERVICE_CALL_REASONS = gql`
  query HeadlessServiceCallReasons($skip: Int, $take: Int, $where: HeadlessServiceCallReasonWhereInput, $orderBy: [HeadlessServiceCallReasonOrderByInput!]) {
    headlessServiceCallReasons(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
      id
      description
      code
    }
    headlessServiceCallReasonCount(where: $where)
  }
`
