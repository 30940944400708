export const LAYOUT_SET_ASIDE_SHOW = 'LAYOUT_SET_ASIDE_SHOW'
export const LAYOUT_SET_SIDEBAR_SHOW = 'LAYOUT_SET_SIDEBAR_SHOW'
export const LAYOUT_SET_DARK_MODE = 'LAYOUT_SET_DARK_MODE'

export const setAside = (show) => {
  return {
    type: LAYOUT_SET_ASIDE_SHOW,
    val: show,
  }
}

export const setSidebar = (show) => {
  return {
    type: LAYOUT_SET_SIDEBAR_SHOW,
    val: show,
  }
}

export const setDarkMode = (val) => {
  return {
    type: LAYOUT_SET_DARK_MODE,
    val,
  }
}
