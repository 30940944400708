import React from 'react'
import { CFooter, CLink } from '@coreui/react'

const TheFooter = () => {
  return (
    <CFooter fixed={false}>
      <div>
        <CLink href="https://www.oos-studio.com" target="_blank">
          Powered by oos
        </CLink>
      </div>
    </CFooter>
  )
}

export default React.memo(TheFooter)
