import { LAYOUT_SET_ASIDE_SHOW, LAYOUT_SET_SIDEBAR_SHOW, LAYOUT_SET_DARK_MODE } from './actions'

const initialState = {
  sidebarShow: 'responsive',
  asideShow: false,
  darkMode: false,
}

const layout = (state = initialState, { type, val }) => {
  switch (type) {
  case LAYOUT_SET_ASIDE_SHOW:
    return { ...state, asideShow: val }
  case LAYOUT_SET_SIDEBAR_SHOW:
    return { ...state, sidebarShow: val }
  case LAYOUT_SET_DARK_MODE:
    return { ...state, darkMode: val }
  default:
    return state
  }
}

export default layout
