export let config = {
  graphQLPath: null,
  baseUrl: null,
}

export const updateConfig = (_config) => {
  config = {
    ...config,
    ..._config,
  }
}
