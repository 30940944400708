import { gql } from '@apollo/client/core'
import { serviceCall, ovenFurnaceSafetyReport, combustionReport } from "./selections";

export const SIGN_IN = gql`
  mutation Login($email: String!, $password: String!) {
    login(data: { app: WEB, email: $email, password: $password }) {
      token
    }
  }
`

export const CREATE_NO_SIGNATURE_REASON = gql`
  mutation CreateOneNoSignatureReason($code: String!, $description: String!) {
    createOneNoSignatureReason(data: { code: $code, description: $description }) {
      id
      code
      description
    }
  }
`

export const UPDATE_NO_SIGNATURE_REASON = gql`
  mutation UpdateOneNoSignatureReason($id: Int!, $code: String!, $description: String!) {
    updateOneNoSignatureReason(data: { code: { set: $code }, description: { set: $description } }, where: { id: $id }) {
      id
      code
      description
    }
  }
`

export const DELETE_NO_SIGNATURE_REASON = gql`
  mutation DeleteOneNoSignatureReason($id: Int!) {
    deleteOneNoSignatureReason(where: { id: $id }) {
      id
    }
  }
`

export const DELETE_NO_SIGNATURE_REASONS = gql`
  mutation DeleteManyNoSignatureReason($ids: [Int!]!) {
    deleteManyNoSignatureReason(where: { id: { in: $ids } }) {
      count
    }
  }
`

export const UPDATE_USER = gql`
  mutation UpdateOneUser($id: Int!, $name: String, $email: String!, $employee: EmployeeUpdateOneWithoutUsersInput!) {
    updateOneUser(data: { name: { set: $name }, email: { set: $email }, employee: $employee }, where: { id: $id }) {
      id
      name
      email
      password
      roles {
        name
      }
      employee {
        id
        fullName
      }
    }
  }
`

export const UPDATE_USER_UIMODE = gql`
  mutation UpdateUserUIMode($id: Int!, $uiMode: UIMode!) {
    updateOneUser(
      data: {
        uiMode: { set: $uiMode }
      },
      where: {
        id: $id
      }
    ){
      id
      uiMode
    }
  }
`

export const ADD_USER_ROLE = gql`
  mutation AddUserRole($role: Role!, $userId: Int!) {
    updateOneUser(data: { roles: { create: { name: $role } } }, where: { id: $userId }) {
      id
      email
      roles {
        name
      }
    }
  }
`

export const REMOVE_USER_ROLE = gql`
  mutation RemoveUserRole($role: Role!, $userId: Int!) {
    updateOneUser(data: { roles: { deleteMany: { name: { equals: $role } } } }, where: { id: $userId }) {
      id
      email
      roles {
        name
      }
    }
  }
`

export const REGISTER = gql`
  mutation Register($token: String!, $name: String!, $password: String!, $confirmPassword: String!) {
    register(data: { token: $token, name: $name, password: $password, confirmPassword: $confirmPassword }) {
      token
    }
  }
`

export const DELETE_USER = gql`
  mutation DeleteOneUser($id: Int!) {
    deleteOneUser(id: $id) {
      id
    }
  }
`

export const DELETE_USERS = gql`
  mutation DeleteManyUsers($ids: [Int!]!) {
    deleteManyUser(ids: $ids)
  }
`

export const UPDATE_MOBILE_LOCATION = gql`
  mutation UpdateOneMobileLocation($id: Int, $data: MobileLocationUpdateInput!){
    updateOneMobileLocation(data: $data, where: {
      id: $id
    })
    {
      locations{
        name
      }
      client{
        shortName
        id
      }
    }
  }
`


export const CREATE_MOBILE_LOCATION = gql`
  mutation CreateOneMobileLocation($name: String!, $client: ClientCreateNestedOneWithoutMobileLocationInput!, $locations: LocationCreateNestedManyWithoutMobileLocationInput!
  ){
    createOneMobileLocation(data: {
      name: $name,
      client: $client,
      locations: $locations
    })
    {
      locations{
        name
      }
      client{
        shortName
      }
    }
  }
`

export const DELETE_MOBILE_LOCATION = gql`
  mutation DeleteMobileLocation($id: Int!) {
    deleteMobileLocation(id: $id) {
      id
    }
  }
`

export const DELETE_MOBILE_LOCATIONS = gql`
  mutation DeleteMobileLocations($ids: [Int!]!) {
    deleteManyMobileLocation(ids: $ids)
  }
`

export const RESET_PASSWORD = gql`
  mutation AdminResetPassword($id: Int!, $password: String!, $confirmPassword: String!) {
    adminResetPassword(data: { id: $id, password: $password, confirmPassword: $confirmPassword }) {
      id
    }
  }
`

export const RESEND_INVITE = gql`
  mutation ResendInvite($id: Int!) {
    resendInvite(data: { id: $id }) {
      id
    }
  }
`

export const CREATE_USER = gql`
  mutation CreateOneUser($name: String, $email: String!, $password: String, $roles: [Role!], $employeeId: Int) {
    createOneUser(data: { name: $name, email: $email, password: $password, roles: $roles, employeeId: $employeeId }) {
      id
      name
      email
      password
      roles {
        name
      }
      employee {
        id
        fullName
      }
    }
  }
`

export const UPDATE_SERVICE_CALL_STATUS = gql`
  mutation UpdateOneServiceCall($id: Int!, $status: ServiceCallStatus!) {
    updateOneServiceCall(data: { status: { set: $status } }, where: { id: $id }) {
      id
      workOrder {
        id
        number
        client {
          id
          fullName
        }
        location {
          id
          line1
          line2
          city
          state
          zip
        }
        purchaseOrderNumber
        notes
        orderDate
      }
      scheduledStart
      scheduledEnd
      actualStart
      actualEnd
      employee {
        firstName
        lastName
      }
      status
      submitTime
    }
  }
`

export const UPDATE_SERVICE_CALL_STATUSES = gql`
  mutation UpdateOneServiceCall($ids: [Int!], $status: ServiceCallStatus!) {
    updateManyServiceCall(data: { status: { set: $status } }, where: { id: { in: $ids } }) {
      count
    }
  }
`

export const UPDATE_SERVICE_CALL = gql`
  mutation UpdateOneServiceCall($id: Int!, $actualStart: DateTime, $actualEnd: DateTime, $receiptEmail: String, $richText: String, $status: ServiceCallStatus!, $newCustomerName: String, $newLocation: String, $newSupervisorName: String, $callerName: String, $callBackNumber: String, $callTime: DateTime, $paymentType: PaymentType, $paymentTypeDescription: String, $billingAddress: String, $issueDescription: String) {
    updateOneServiceCall(
      data: {
        actualStart: { set: $actualStart },
        actualEnd: { set: $actualEnd },
        receiptEmail: { set: $receiptEmail },
        richText: { set: $richText },
        status: { set: $status },
        newCustomerName: { set: $newCustomerName },
        newLocation: { set: $newLocation },
        newSupervisorName: { set: $newSupervisorName },
        callerName: { set: $callerName },
        callBackNumber: { set: $callBackNumber },
        callTime: { set: $callTime },
        paymentType: { set: $paymentType },
        paymentTypeDescription: { set: $paymentTypeDescription },
        billingAddress: { set: $billingAddress },
        issueDescription: { set: $issueDescription },
      }
      where: { id: $id }
    ) {
      id
      workOrder {
        id
        number
        client {
          id
          fullName
        }
        location {
          id
          line1
          line2
          city
          state
          zip
        }
        purchaseOrderNumber
        notes
        orderDate
      }
      scheduledStart
      scheduledEnd
      actualStart
      actualEnd
      employee {
        firstName
        lastName
      }
      status
      submitTime
      richText
      newCustomerName
      newLocation
      newSupervisorName
      callerName
      callBackNumber
      callTime
      paymentType
      paymentTypeDescription
      billingAddress
      issueDescription
      timeOnly
    }
  }
`

export const CREATE_SERVICE_REPORT_PART = gql`
  mutation CreateServiceReportPart($part: [ServiceReportPartCreateWithoutServiceReportInput!], $serviceReportId: Int!) {
    updateOneServiceReport(data: { parts: { create: $part } }, where: { id: $serviceReportId }) {
      id
      parts {
        id
        uuid
        updatedAt
        quantity
        number
        description
        partType
      }
    }
  }
`

export const MERGE_SERVICE_CALLS = gql`
  mutation MergeServiceCalls($ids: [Int!]!) {
    mergeServiceCalls(
      data: $ids
    ) {
      id
    }
  }
`

export const UNMERGE_SERVICE_CALLS = gql`
  mutation UnmergeServiceCalls($headerServiceCallId: Int!) {
    unmergeServiceCalls(
      id: $headerServiceCallId
    ) {
      id
    }
  }
`

export const CANCEL_SERVICE_CALL = gql`
  mutation CancelServiceCall($id: Int!, $commuteTime: Float!){
    updateOneServiceCall(
      data: {
        status: { set: CANCELLED }
        commuteTime: { set: $commuteTime }
      },
      where: {
        id: $id
      }
    ) ${serviceCall}
  }
`

export const CANCEL_SERVICE_CALLS = gql`
  mutation CancelServiceCalls($ids: [Int!], $commuteTime: Float!){
    updateManyServiceCall(
      data: {
        status: { set: CANCELLED }
        commuteTime: { set: $commuteTime }
      },
      where: {
        id: {
          in: $ids
        }
      }
    ) {
      count
    }
  }
`

export const DELETE_SERVICE_REPORT_PART = gql`
  mutation DeleteServiceReportPart($partId: Int!, $serviceReportId: Int!) {
    updateOneServiceReport(data: { parts: { delete: { id: $partId } } }, where: { id: $serviceReportId }) {
      id
      parts {
        id
        uuid
        updatedAt
        quantity
        number
        description
        partType
      }
    }
  }
`

export const UPDATE_SERVICE_REPORT = gql`
  mutation UpdateServiceReport($id: Int!, $data: ServiceReportUpdateInput! ) {
    updateOneServiceReport(data: $data, where: {
      id: $id
    }) {
      id
      servicePerformed
      additionalWorkRequirements
      internalFollowUp
      parts {
        id
        uuid
        updatedAt
        quantity
        number
        description
        partType
      }
    }
  }
`

export const UPDATE_COMBUSTION_REPORT = gql`
  mutation updateCombustionReport($id: Int!, $data: CombustionReportUpdateInput!) {
    updateOneCombustionReport(data: $data, where: { id: $id }) ${combustionReport}
  }
`

export const UPDATE_OVEN_FURNACE_SAFETY_REPORT = gql`
  mutation updateOvenFurnaceSafetyReport($id: Int!, $fields: OvenFurnaceSafetyReportUpdateInput!) {
    updateOneOvenFurnaceSafetyReport(data: $fields, where: { id: $id }) ${ovenFurnaceSafetyReport}
    }
`

export const ADD_SAFETY_REPORT = gql`
    mutation addSafetyReport($serviceCallId: Int!, $equipmentUUID: String!, $uuid: String!){
        updateOneServiceCall(data: {
            ovenFurnaceSafetyReports: {
                create: {
                    equipment: {
                        connect: {
                            uuid: $equipmentUUID
                        }
                    }
                    uuid: $uuid
                }
            }
        },
        where: {
            id: $serviceCallId
        })
        ${serviceCall}
    }
`

export const ADD_SAFETY_REPORT_WITH_HEADER = gql`
    mutation addSafetyReport($serviceCallId: Int!, $equipmentUUID: String!, $uuid: String!, $serviceCallHeaderId: Int!){
        updateOneServiceCall(data: {
            ovenFurnaceSafetyReports: {
                create: {
                    equipment: {
                        connect: {
                            uuid: $equipmentUUID
                        }
                    }
                    headerServiceCall: {
                        connect: {
                            id: $serviceCallHeaderId
                        }
                    }
                    uuid: $uuid
                }
            }
        },
        where: {
            id: $serviceCallId
        })
        ${serviceCall}
    }
`

export const DELETE_ONE_SAFETY_REPORT = gql`
    mutation deleteOneSafetyReport($serviceCallId: Int!, $uuid: String!){
        updateOneServiceCall(data: {
          ovenFurnaceSafetyReports: {
            delete: {
              uuid: $uuid
            }
          }
        },
        where: {
          id: $serviceCallId
        })
        ${serviceCall}
    }
`

export const DELETE_ONE_CHILD_SAFETY_REPORT = gql`
    mutation deleteOneSafetyReport($serviceCallId: Int!, $uuid: String!){
        updateOneServiceCall(data: {
          ovenFurnaceSafetyReportsFromChildren: {
            delete: {
              uuid: $uuid
            }
          }
        },
        where: {
          id: $serviceCallId
        })
        ${serviceCall}
    }
`

export const ADD_COMBUSTION_REPORT = gql`
    mutation addCombustionReport($serviceCallId: Int!, $equipmentUUID: String!, $uuid: String!){
        updateOneServiceCall(data: {
            combustionReports: {
                create: {
                    equipment: {
                        connect: {
                            uuid: $equipmentUUID
                        }
                    }
                    uuid: $uuid
                }
            }
        },
        where: {
            id: $serviceCallId
        })
        ${serviceCall}
    }
`

export const ADD_COMBUSTION_REPORT_WITH_HEADER = gql`
    mutation addCombustionReport($serviceCallId: Int!, $equipmentUUID: String!, $uuid: String!, $serviceCallHeaderId: Int!){
        updateOneServiceCall(data: {
            combustionReports: {
                create: {
                    equipment: {
                        connect: {
                            uuid: $equipmentUUID
                        }
                    }
                    headerServiceCall: {
                        connect: {
                            id: $serviceCallHeaderId
                        }
                    }
                    uuid: $uuid
                }
            }
        },
        where: {
            id: $serviceCallId
        })
        ${serviceCall}
    }
`

export const DELETE_ONE_COMBUSTION_REPORT = gql`
    mutation deleteOneCombustionReport($serviceCallId: Int!, $uuid: String!){
        updateOneServiceCall(data: {
          combustionReports: {
            delete: {
              uuid: $uuid
            }
          }
        },
        where: {
          id: $serviceCallId
        })
        ${serviceCall}
    }
`

export const DELETE_ONE_CHILD_COMBUSTION_REPORT = gql`
    mutation deleteOneCombustionReport($serviceCallId: Int!, $uuid: String!){
        updateOneServiceCall(data: {
          combustionReportsFromChildren: {
            delete: {
              uuid: $uuid
            }
          }
        },
        where: {
          id: $serviceCallId
        })
        ${serviceCall}
    }
`

export const DELETE_ONE_SERVICE_REPORT = gql`
    mutation deleteOneServiceReport($serviceCallId: Int!, $uuid: String!, $updateManyWorkOrderParts:[WorkOrderPartUpdateManyWithWhereWithoutWorkOrderInput!]){
        updateOneServiceCall(data: {
            workOrder: {
              update: {
                parts: {
                  updateMany: $updateManyWorkOrderParts,
                }
              }
            },
            serviceReports: {
                delete: {
                    uuid: $uuid
                }
            }
          },
          where: {
              id: $serviceCallId
          })
        ${serviceCall}
    }
`

export const DELETE_ONE_CHILD_SERVICE_REPORT = gql`
    mutation deleteOneServiceReport($serviceCallId: Int!, $uuid: String!, $updateManyWorkOrderParts:[WorkOrderPartUpdateManyWithWhereWithoutWorkOrderInput!]){
        updateOneServiceCall(data: {
            workOrder: {
              update: {
                parts: {
                  updateMany: $updateManyWorkOrderParts,
                }
              }
            },
            serviceReportsFromChildren: {
                delete: {
                    uuid: $uuid
                }
            }
          },
          where: {
              id: $serviceCallId
          })
        ${serviceCall}
    }
`

export const ADD_SERVICE_REPORT = gql`
    mutation addServiceReport($serviceCallId: Int!, $equipmentUUID: String!, $uuid: String!){
        updateOneServiceCall(
          data: {
              serviceReports: {
                  create: {
                      equipment: {
                          connect: {
                              uuid: $equipmentUUID
                          }
                      }
                    uuid: $uuid
                  }
              }
          },
          where: {
              id: $serviceCallId
          }
        )
        ${serviceCall}
    }
`

export const ADD_SERVICE_REPORT_WITH_HEADER = gql`
    mutation addServiceReport($serviceCallId: Int!, $equipmentUUID: String!, $uuid: String!, $serviceCallHeaderId: Int!){
        updateOneServiceCall(
          data: {
              serviceReports: {
                  create: {
                      equipment: {
                          connect: {
                              uuid: $equipmentUUID
                          }
                      }
                      headerServiceCall: {
                          connect: {
                              id: $serviceCallHeaderId
                          }
                    }
                    uuid: $uuid
                  }
              }
          },
          where: {
              id: $serviceCallId
          }
        )
        ${serviceCall}
    }
`

export const ASSIGN_WORK_ORDER_TO_SERVICE_CALL = gql`
mutation AssignWorkOrder($serviceCallId:Int!, $workOrderId:Int!, $matchedHeadlessEquipment:[Int!], $matchedExistingEquipment: [Int!]){
  assignWorkOrder(data:{
    serviceCallId:$serviceCallId,
    workOrderId:$workOrderId,
    matchedHeadlessEquipment:$matchedHeadlessEquipment,
    matchedExistingEquipment:$matchedExistingEquipment,
  }) {
    workOrder{
      id
    }
}
}
`

export const SEND_SERVICE_CALL_EMAIL = gql`
  mutation sendServiceCallEmail($data: ServiceCallEmailInput!){
    sendServiceCallEmail(data: $data) {
      id
      emailSent
    }
  }
`

export const UPDATE_ATTACHMENT = gql`
  mutation updateAttachment($id: Int!, $attachToEmail: Boolean!) {
    updateOneAttachment(data: {
      attachToEmail: {
        set: $attachToEmail
      }
    }, where:{
      id: $id
    }) {
      id
      name
      url
      attachToEmail
      serviceCall {
        id
      }
    }
  }
`

export const CREATE_EQUIPMENT = gql`
  mutation CreateOneEquipment($data: Equipment_CreateInput!) {
    createOneEquipment_(data: $data) {
      id
    }
  }
`

export const UPDATE_EQUIPMENT = gql`
  mutation UpdateOneEquipment($id: Int!, $data: Equipment_UpdateInput!) {
    updateOneEquipment_(data: $data, where: {
      id: $id
    }) {
      id
    }
  }
`

export const MOVE_EQUIPMENT = gql`
  mutation MoveOneEquipment($id: Int!, $locId: Int!) {
    updateOneEquipment_(
      data: {
        customerLocation: {
          connect: {
            id: $locId
          }
        }
      },
      where: {
        id: $id
      }
    ) {id}
  }
`

export const DELETE_EQUIPMENT = gql`
  mutation DeleteOneEquipment($id: Int!) {
    deleteOneEquipment_(id: $id)
  }
`

export const DELETE_EQUIPMENTS = gql`
  mutation DeleteManyEquipment($ids: [Int!]!) {
    deleteManyEquipment_(ids: $ids)
  }
`

export const DELETE_SERVICE_CALL = gql`
  mutation DeleteServiceCall($id: Int!){
    deleteManyServiceCall(
      ids: [$id]
    )
  }
`

export const DELETE_SERVICE_CALLS = gql`
  mutation DeleteServiceCalls($ids: [Int!]!) {
    deleteManyServiceCall(
      ids: $ids
    )
  }
`

export const SET_MANY_SERVICE_CALLS_ON_HOLD_STATUS = gql`
 mutation SetManyServiceCallsOnHoldStatus($ids: [Int!], $onHold: Boolean!) {
    updateManyServiceCall(
      data: {
        onHold: { set: $onHold }
      },
      where: {
        id: {
          in: $ids
        }
      }
    ) {
        count
      }
  }
`

export const CREATE_HEADLESS_SERVICE_CALL_REASON = gql`
  mutation CreateOneHeadlessServiceCallReason($code: String!, $description: String!) {
    createOneHeadlessServiceCallReason(data: { code: $code, description: $description }) {
      id
      code
      description
    }
  }
`

export const UPDATE_HEADLESS_SERVICE_CALL_REASON = gql`
  mutation UpdateOneHeadlessServiceCallReason($id: Int!, $code: String!, $description: String!) {
    updateOneHeadlessServiceCallReason(data: { code: { set: $code }, description: { set: $description } }, where: { id: $id }) {
      id
      code
      description
    }
  }
`

export const DELETE_HEADLESS_SERVICE_CALL_REASON = gql`
  mutation DeleteOneHeadlessServiceCallReason($id: Int!) {
    deleteOneHeadlessServiceCallReason(where: { id: $id }) {
      id
    }
  }
`

export const DELETE_HEADLESS_SERVICE_CALL_REASONS = gql`
  mutation DeleteManyHeadlessServiceCallReason($ids: [Int!]!) {
    deleteManyHeadlessServiceCallReason(where: { id: { in: $ids } }) {
      count
    }
  }
`

export const UPDATE_WORK_ORDER_LOCATION = gql`
  mutation UpdateWorkOrderLocation($id: Int!, $locationId: Int!) {
    updateOneWorkOrder(data: {
      location: {
        connect: {
          id: $locationId
        }
      }
    }, where: {
      id: $id
    }) {
      id
    }
  }
`
