import React from 'react'

const Users = React.lazy(() => import('./users/screens/Users'))
const ServiceCalls = React.lazy(() => import('./serviceCalls/screens/ServiceCalls'))
const ReasonCodes = React.lazy(() => import('./reasonCodes/screens/ReasonCodes'))
const Equipment = React.lazy(() => import('./equipment/screens/Equipment'))
const Locations = React.lazy(() => import('./locations/screens/Locations'))
const Timeline = React.lazy(() => import('./timeline/screens/Timeline'))
const Calendar = React.lazy(() => import('./calendar/screens/Calendar'))

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/users', name: 'Users', component: Users },
  { path: '/service-calls', name: 'Service Calls', component: ServiceCalls },
  { path: '/reason-codes/:tab?', name: 'Reason Codes', component: ReasonCodes },
  { path: '/equipment', name: 'Equipment', component: Equipment },
  { path: '/locations/:tab?', name: 'Locations', component: Locations },
  { path: '/timeline', name: 'Timeline', component: Timeline },
  { path: '/calendar', name: 'Calendar', component: Calendar }
]

export default routes
