import { sygnet } from './sygnet'
import { logo } from './logo'
import { logoNegative } from './logo-negative'

import {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cilGroup,
  cilMobile,
  cilNotes,
  cilFactory,
  cilCheck,
  cilCheckCircle,
  cilCheckAlt,
  cilCaretBottom,
  cilCaretTop,
  cilArrowTop,
  cilArrowBottom,
  cilBuilding,
  cilAvTimer,
  cilCalendar
} from '@coreui/icons'

export const icons = Object.assign(
  {},
  {
    sygnet,
    logo,
    logoNegative,
  },
  {
    logo,
    cilApplicationsSettings,
    cilSpeedometer,
    cilSun,
    cilMoon,
    cilGroup,
    cilMobile,
    cilNotes,
    cilFactory,
    cilCheck,
    cilCheckCircle,
    cilCheckAlt,
    cilCaretBottom,
    cilCaretTop,
    cilArrowTop,
    cilArrowBottom,
    cilBuilding,
    cilAvTimer,
    cilCalendar
  },
)
