import React from 'react'
import { CSidebar, CSidebarClose } from '@coreui/react'
import { setAside } from '../actions'
import { connect } from 'react-redux'

const TheAside = ({ show, setAside }) => {
  const setState = (state) => setAside(state)

  return (
    <CSidebar aside colorScheme="light" size="lg" overlaid show={show} onShowChange={(state) => setState(state)}>
      <CSidebarClose onClick={() => setState(false)} />
      {/*aside content*/}
      <div className="nav-underline">
        <div className="nav nav-tabs">
          <div className="nav-item">
            <div className="nav-link">Aside</div>
          </div>
        </div>
      </div>
    </CSidebar>
  )
}

const mapDispatchToProps = {
  setAside,
}

const mapStateToProps = (state) => {
  return {
    show: state.layout.asideShow,
  }
}

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(TheAside))
