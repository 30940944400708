import { setDarkMode } from "../layout/actions";
export const SIGN_OUT = 'SIGN_OUT'
export const RECEIVE_USER = 'RECEIVE_USER'

export const signOut = () => {
  const loginToken = localStorage.getItem('loginToken')
  if (loginToken) {
    localStorage.removeItem('loginToken')
    window.location.reload()
  }
  return {
    type: SIGN_OUT,
  }
}

export const receiveUser = user => dispatch => {
  if (user?.uiMode === 'DARK') {
    dispatch(setDarkMode(true))
  }
  dispatch({
    type: RECEIVE_USER,
    user,
  })
}
