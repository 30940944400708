import React, { useEffect, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { withRouter, useHistory } from 'react-router-dom'
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap'
import '../styles.scss'
import { SIGN_IN } from '../../mutations'
import { ME } from "../../queries";
import { receiveUser } from "../../auth/actions";
import { useDispatch, useSelector } from "react-redux";

const SignIn = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const userRole = useSelector(state => state?.auth?.user)?.roles?.find((role) => role?.name)

  const loginToken = localStorage.getItem('loginToken')

  const [formState, setFormState] = useState({
    email: '',
    password: '',
  })

  const [getMe, { data: userData }] = useLazyQuery(ME, {
    fetchPolicy: "no-cache"
  })

  useEffect(() => {
    if (userData?.me) {
      dispatch(receiveUser(userData.me))
    }
  }, [userData])

  const [signIn, { loading: mutationLoading, error: mutationError }] = useMutation(SIGN_IN, {
    variables: {
      app: 'WEB',
      email: formState.email,
      password: formState.password,
    },
    onCompleted: async ({ login }) => {
      await localStorage.setItem('loginToken', login.token)
      getMe()
    },
    onError: (err) => {
      console.log(err)
    },
  })

  const submitSignIn = async (e) => {
    if (e.keyCode === 13) await signIn()
  }

  if (loginToken) {
    if (userRole && userRole?.name !== 'FIELDTECH') {
      history.push('/service-calls')
    }
    if (userRole?.name === 'FIELDTECH') {
      localStorage.removeItem('loginToken')
    }
    if (!userRole) {
      return null
    }
  }

  return (
    <div className={'sign-in-container app flex-row align-items-center'}>
      <Container>
        <Row className={'justify-content-center'}>
          <Col className={'signIn-form'}>
            <CardGroup>
              <Card className={'p-4 signIn-card'}>
                <CardBody>
                  <h1>Sign In</h1>
                  <p className={'text-muted'}>Sign in to your account</p>
                  {mutationError ? (
                    <Alert color={'danger'} className={'fade show'}>
                      {mutationError.message ? mutationError.message : 'An error occurred signing in'}
                    </Alert>
                  ) : (
                    ''
                  )}
                  <InputGroup className={'mb-3'}>
                    <InputGroupAddon addonType={'prepend'}>
                      <InputGroupText style={{ width: 35 }}>
                        <i className={'fa fa-user'} />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      value={formState.email}
                      onChange={(e) =>
                        setFormState({
                          ...formState,
                          email: e.target.value,
                        })
                      }
                      onKeyDown={submitSignIn}
                      type={'text'}
                      placeholder={'Email'}
                    />
                  </InputGroup>
                  <InputGroup className={'mb-4'}>
                    <InputGroupAddon addonType={'prepend'}>
                      <InputGroupText style={{ width: 35 }}>
                        <i className={'fa fa-lock'} />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      value={formState.password}
                      onChange={(e) =>
                        setFormState({
                          ...formState,
                          password: e.target.value,
                        })
                      }
                      onKeyDown={submitSignIn}
                      type={'password'}
                      placeholder={'Password'}
                    />
                  </InputGroup>
                  <Row>
                    <Col xs={'6'}>
                      <Button color={'primary'} className={'px-4'} disabled={mutationLoading} onClick={signIn}>
                        Sign In
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default withRouter(SignIn)
